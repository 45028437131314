<template>
  <div class="userInfo">
    <div class="userBox">
      <div class="user">
        <figure class="userImg">
          <!-- <img class="userImgIcon" v-if="userData.UserHeadPhoto"
          :src="userData.UserHeadPhoto && imgUrlFilter(userData.UserHeadPhoto)" />-->
          <van-image
            class="userImgIcon"
            :src="
              userData.UserHeadPhoto && imgUrlFilter(userData.UserHeadPhoto)
            "
            @click="imagePreview(userData.UserHeadPhoto)"
          >
            <template v-slot:error>
              <img
                class="userImgIcon"
                src="~assets/img/userManage/img_logo@2x.png"
              />
            </template>
          </van-image>
          <!-- <img class="userImgIcon"
               v-else
          src="~assets/img/userManage/img_logo@2x.png" />-->
        </figure>
        <p class="userName" v-if="userData.NickName">{{ userData.NickName }}</p>
        <p class="userName" v-else @click="goEditName">
          {{ $t.auth.editName }}
        </p>
      </div>
    </div>
    <div class="bg">
      <img class="ngImg" src="~assets/img/userManage/bg_1@2x.png" />
    </div>
    <div class="message" @click="getNewsCenter">
      <van-badge
        color="#ff5852"
        :content="
          userData.UnreadMessageCount ? userData.UnreadMessageCount : null
        "
      >
        <img
          class="setImg"
          src="~assets/img/userManage/icon_my_message@2x.png"
        />
      </van-badge>
    </div>
    <div class="set" @click="goSet">
      <img
        class="setImg"
        src="~assets/img/userManage/icon_mine_setting@2x.png"
      />
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Image, Badge } from "vant";
Vue.use(Image).use(Badge);

export default {
  name: "UserInfo",
  props: ["userData"],
  data() {
    return {};
  },
  methods: {
    //预览图片
    imagePreview(image) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.picturePreview, {
        pictureList: [image],
      });
    },
    // 跳转设置页面
    getNewsCenter() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "messages",
        name: "Messages",
        query: [],
      });
    },
    goSet() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "user/setUser",
        name: "setUser",
        query: [],
      });
    },
    goEditName() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "user/editNickname",
        name: "EditNickname",
        query: [],
      });
    },
    imgUrlFilter(value) {
      // console.log(value.split("|")[0]);
      return this.$api.formatImageUrl(value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.userInfo {
  width: 100%;
  position: relative;
  .bg {
    width: 100%;
    overflow: hidden;
    z-index: 1;
    .ngImg {
      width: 100%;
    }
  }
  .userBox {
    top: 0;
    position: absolute;
    width: 100%;
    .user {
      width: 86.6%;
      margin: 0 auto;
      background-color: transparent;
      margin-top: 12%;
      @include publicFlex;
      .userImg {
        width: 62px;
        height: 62px;
        border-radius: 100%;
        margin: 0;
        padding: 0;
        ::v-deep .van-image__error {
          border-radius: 100%;
        }
        ::v-deep .van-image__img {
          border-radius: 100%;
          object-fit: cover;
        }
        .userImgIcon {
          //width: 100%;
          width: 62px;
          height: 62px;
          object-fit: cover;
          -webkit-object-fit: cover;
          border-radius: 100%;
        }
      }
      .userName {
        color: #ffffff;
        font-weight: bold;
        font-size: 17px;
        margin-left: 14px;
        flex: 1;
        -webkit-flex: 1;
      }
    }
  }
  .message {
    position: absolute;
    top: 16px;
    right: 50px;
    .setImg {
      width: 35px;
      height: 35px;
    }
    ::v-deep .van-badge {
      top: 5px !important;
      right: 5px !important;
      border: none !important;
      padding: 1px 1px 1px 1px;
    }
  }
  .set {
    top: 20px;
    position: absolute;
    top: 20px;
    right: 20px;
    .setImg {
      width: 25px;
      height: 25px;
    }
  }
}
</style>
