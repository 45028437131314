<template>
  <div class="userManage">
    <van-pull-refresh v-model="refresh" @refresh="onRefresh">
      <user-info :userData="userData"></user-info>
      <record :userData="userData"></record>
      <order :OrderCount="OrderCount"></order>
    </van-pull-refresh>

    <van-dialog
      v-model="showCoupon"
      :title="$t.couponInterpret.alertTitle"
      @confirm="userConfirmCoupon"
      :confirm-button-text="$t.meta.confirm"
      show-confirm-button
      :show-cancel-button="false"
      theme="round-button"
    >
      <slot name="default">
        <div class="coupon-alert">
          <div
            class="coupon-item"
            v-for="(item, index) in userData.CouponUserNoticeList"
            :key="index"
          >
            <div class="coupon-box">
              <div class="amount">
                <span class="symbol">¥</span>{{ item.DiscountAmount }}
              </div>
              <div class="amount-right">
                <div class="condition" v-if="!item.IsNoThreshold">
                  {{ $t.couponInterpret.condition(item.UseCondition) }}
                </div>
                <div class="condition" v-else>
                  {{ $t.couponInterpret.noCondition }}
                </div>
                <div class="date">
                  {{ $t.couponInterpret.endDate }} : {{ item.MaturityTime }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </slot>
    </van-dialog>
  </div>
</template>
<script>
import UserInfo from "./components/UserInfo";
import Record from "./components/Record";
import Order from "./components/Order";
import { mapGetters } from "vuex";
import { PullRefresh } from "vant";

export default {
  name: "UserManage",
  components: {
    UserInfo,
    Record,
    Order,
    "van-pull-refresh": PullRefresh,
  },
  computed: {
    ...mapGetters(["isLogging"]),
  },
  data() {
    return {
      showCoupon: false,
      refresh: false,
      userData: {},
      OrderCount: {},
    };
  },
  beforeMount() {
    !this.isLogging &&
      this.$router.replace({
        path: "/auth",
        query: { redirect: this.$route.fullPath },
      });
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    userConfirmCoupon() {
      this.$commonMethod.showLoading();
      let param = {};
      this.$api.coupon
        .userConfirmCoupon(param)
        .then(() => {
          this.$commonMethod.hideLoading();
        })
        .catch((error) => {
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    onRefresh() {
      this.refresh = true;
      this.getUserInfo(true)
        .then(() => {
          this.refresh = false;
        })
        .catch(() => {
          this.refresh = false;
        });
    },
    getUserInfo(hideLoading = false) {
      return new Promise((resolve, reject) => {
        if (!hideLoading) {
          this.$commonMethod.showLoading();
        }
        let param = {};
        this.$api.user
          .getUserInfo(param)
          .then((res) => {
            this.userData = res.data;
            this.OrderCount = this.userData.OrderCount;
            if (!hideLoading) {
              this.$commonMethod.hideLoading();
            }
            if (
              this.userData.CouponUserNoticeList &&
              this.userData.CouponUserNoticeList.length > 0
            ) {
              this.showCoupon = true;
            } else {
              this.showCoupon = false;
            }
            resolve();
          })
          .catch((error) => {
            this.isfirst = false;
            if (!hideLoading) {
              this.$commonMethod.hideLoading();
            }
            this.$commonEnv.commonAction(
              this.$commonEnv.actionType.showDialog,
              {
                msg: error.message,
                status: error.status,
              }
            );
            reject();
          });
      });
    },
    goSet() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "user/setUser",
        name: "setUser",
        query: [],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.userManage {
  width: 100%;
  .van-pull-refresh {
    overflow: unset;
  }
  .coupon-alert {
    margin: 5%;
    font-size: 14px;
    overflow-y: scroll;
    height: 320px;
    .coupon-item {
      margin: 15px;
      padding: 15px;
      border-radius: 10px;
      line-height: 50px;
      box-shadow: 0px 2px 2px 2px #e5e5e5;
      .coupon-box {
        text-align: left;
        .amount {
          color: #01aaa3;
          font-size: 25px;
          font-weight: bold;
          text-align: center;
          width: 30%;
          display: inline-block;
          vertical-align: middle;
          .symbol {
            font-size: 14px;
            margin-right: 5px;
          }
        }
        .amount-right {
          width: 70%;
          display: inline-block;
          vertical-align: middle;
          .condition {
            color: #999;
            font-size: 12px;
            line-height: 2;
          }
          .date {
            color: #999;
            font-size: 12px;
            line-height: 2;
          }
        }
      }
    }
  }
}
</style>
