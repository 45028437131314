<template>
  <div class="order">
    <div class="myOrder">
      <p class="myOrderTitle">{{ $t.userManage.myOrder }}</p>
      <div class="myOrderLeft" @click="getOrders('0')">
        <p class="myOrderLeftTitle">{{ $t.userManage.allOrders }}</p>
        <img
          class="myOrderLeftLogo"
          src="~assets/img/userManage/icon_money_right@2x.png"
        />
      </div>
    </div>
    <!-- 物流状态 -->
    <ul class="record">
      <li class="itemBox" @click="getOrders('10')">
        <van-badge
          color="#ff5852"
          :content="OrderCount.PaymentCount ? OrderCount.PaymentCount : null"
        >
          <figure class="itemImg">
            <img
              class="itemImgIcon"
              src="~assets/img/userManage/icon_mine_payment@2x.png"
            />
          </figure>
          <p class="itemTitle">{{ $t.userManage.waitPayment }}</p>
        </van-badge>
      </li>
      <li class="itemBox" @click="getOrders('20')">
        <van-badge
          color="#ff5852"
          :content="OrderCount.DeliveryCount ? OrderCount.DeliveryCount : null"
        >
          <figure class="itemImg">
            <img
              class="itemImgIcon"
              src="~assets/img/userManage/icon_mine_box@2x.png"
            />
          </figure>
          <p class="itemTitle">{{ $t.userManage.waitGoods }}</p>
        </van-badge>
      </li>
      <li class="itemBox" @click="getOrders('30')">
        <van-badge
          color="#ff5852"
          :content="
            OrderCount.ReceivingCount ? OrderCount.ReceivingCount : null
          "
        >
          <figure class="itemImg">
            <img
              class="itemImgIcon"
              src="~assets/img/userManage/icon_mine_van@2x.png"
            />
          </figure>
          <p class="itemTitle">{{ $t.userManage.confiscatedGoods }}</p>
        </van-badge>
      </li>
      <li class="itemBox" @click="getOrders('1')">
        <van-badge color="#ff5852" :content="null">
          <figure class="itemImg">
            <img
              class="itemImgIcon"
              src="~assets/img/userManage/icon_mine_done@2x.png"
            />
          </figure>
          <p class="itemTitle">{{ $t.userManage.haveGoods }}</p>
        </van-badge>
      </li>
    </ul>
  </div>
</template>
<script>
import Vue from "vue";
import { Badge } from "vant";

Vue.use(Badge);
export default {
  name: "Order",
  props: ["OrderCount"],
  data() {
    return {};
  },
  methods: {
    // 跳转订单详情
    getOrders(state) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "orders",
        name: "orders",
        query: [
          {
            name: "state",
            value: state,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.order {
  width: 94.36%;
  margin: 0 auto;
  margin-top: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  .myOrder {
    justify-content: space-between;
    -webkit-justify-content: space-between;
    padding: 20px 16px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    @include publicFlex;
    .myOrderTitle {
      font-size: 14px;
      color: #000000;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }
    .myOrderLeft {
      max-width: 75%;
      @include publicFlex;
      .myOrderLeftTitle {
        font-size: 12px;
        color: #a7a7a7;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-right: 10px;
        padding: 0;
      }
      .myOrderLeftLogo {
        width: 12px;
        height: 12px;
      }
    }
  }
  .record {
    width: 100%;
    padding: 0;
    margin: 0 auto;
    justify-content: space-around;
    -webkit-justify-content: space-around;
    list-style: none;
    padding-bottom: 25px;
    @include publicFlex;
    .itemBox {
      text-align: center;
      max-width: 33.3%;
      list-style: none;

      .itemImg {
        width: 42px;
        align-items: flex-end;
        -webkit-align-items: flex-end;
        margin: 0 auto;

        @include publicFlex;
        .itemImgIcon {
          width: 100%;
        }
      }
      .itemTitle {
        color: #333333;
        font-size: 12px;
        margin: 0;
        padding: 0;
      }
      ::v-deep .van-badge {
        top: 5px !important;
        right: 5px !important;
        border: none !important;
        padding: 1px 1px 1px 1px;
      }
    }
  }
}
</style>
